import { FC } from 'react'
import styled from 'styled-components'
import { ListComponentI } from '../../interfaces'
import { BREAKPOINTS } from '../../utils/constants/layout'
import {
    decodeHtmlEntities,
    generateUUID,
    getIconByString
} from '@App/libs/utils/geral'
import { CardDS } from '../geral/JOTADS.components'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import { StoreType } from '@App/api/types/store'
import { makeProURL } from '../home/first-scroll/JotaPRO.component'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'
import router, { useRouter as nextRouter } from 'next/router'
import { CONTEUDO_LIBERADO } from '../home/first-scroll/MiddleColumn.component'
import { PATROCINADO } from '@App/libs/utils/constants/home'
import { spaces } from '../search/searchContants'

import { getColorVertical, isContentPro, isUserPro } from '@App/libs/utils/pro'
import { VerticalName, VerticalType } from '@App/libs/interfaces/PRO.interfaces'

export const transformAuthors = (search: any) => {
    return search.map((item: any) => {
        return {
            ...item,
            _source: {
                ...item._source,
                authors: item._source.authors.map((author: any) => {
                    return {
                        name: author.display_name,
                        url: author.slug
                    }
                })
            }
        }
    })
}

/**
 * List Component
 * @return {React.JSX.Element}
 */
const ListComponent: FC<ListComponentI> = ({
    content,
    type,
    isSearchPage
}): React.JSX.Element => {
    const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.MD}px)`)
    const { auth } = useSelector((state: StoreType) => state)
    const { query } = nextRouter()

    const getDate = (value: string) => {
        const d = new Date(value)
        return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    }

    const showOSCard = (content: any) => {
        const formatHour = (item: any) => {
            const publishedAt = new Date(item._source.source.published_at)
            const hour = String(publishedAt.getUTCHours()).padStart(2, '0')
            const minute = String(publishedAt.getUTCMinutes()).padStart(2, '0')
            const formatedHour = `${hour}:${minute}`
            return formatedHour
        }

        if (content?._source?.source?.type == 'cms.newsletter') {
            return (
                <CardDS
                    key={generateUUID()}
                    title={content._source.title.headline}
                    newsLink={makeProURL(
                        content._source.vertical.slug as VerticalType,
                        content._source.categories[0].slug,
                        content._source.source.slug
                    )}
                    size='small'
                    isContentPro={isContentPro(
                        content._source.vertical.name.toLowerCase(),
                        auth
                    )}
                    isUserPro={isUserPro(
                        content._source.vertical.name.toLowerCase(),
                        auth
                    )}
                    tagLabel={content._source?.categories[0]?.name}
                    tagColor={
                        content._source?.vertical?.name.toLowerCase() ===
                        VerticalName.TRABALHISTA.toLowerCase()
                            ? 'jotaBlackLight'
                            : getColorVertical(content._source?.vertical?.name)
                    }
                    tagDisableHover
                    subjectTagLabel={content?._source?.vertical?.name}
                    subjectTagOnClick={function noRefCheck() {
                        return {}
                    }}
                    subjectTagColor={getColorVertical(
                        content._source?.vertical?.name
                    )}
                    subjectTagDisableHover
                    authorTagLabel={getDate(
                        content._source.source.published_at
                    )}
                    authorTagDisableHover
                    relatedTagLabel={formatHour(content)}
                    relatedTagDisableHover
                    hasSeparator={isMobile}
                    titleSearchTerm={''}
                />
            )
        } else if (content?._source?.source?.type == 'cms.alert') {
            const queryVertical = query.vertical
            let alertType = VerticalName.PODER.toLowerCase()
            let tagLabel = VerticalName.PODER
            let colorByVertical = getColorVertical(VerticalName.PODER)
            let hasPoder = false
            let hasSaude = false

            if (queryVertical === 'pro') {
                const segmentations = content?._source?.segmentations

                hasPoder = segmentations.some((item: any) =>
                    item.slug.includes('poder')
                )

                if (hasPoder) {
                    colorByVertical = getColorVertical(VerticalName.PODER)
                    tagLabel = VerticalName.PODER
                    alertType = VerticalName.PODER
                }

                hasSaude = segmentations.some((item: any) =>
                    item.slug.includes('saude')
                )

                if (hasSaude) {
                    colorByVertical = getColorVertical(VerticalName.SAUDE)
                    tagLabel = VerticalName.SAUDE
                    alertType = VerticalName.SAUDE
                }
            }

            return (
                <CardDS
                    key={generateUUID()}
                    title={content._source.title.headline}
                    newsLink={`/${alertType}/alertas/${content._source.source.slug}`}
                    size='small'
                    isContentPro={isContentPro(alertType as VerticalName, auth)}
                    isUserPro={isUserPro(alertType as VerticalName, auth)}
                    tagLabel={'Alertas'}
                    tagColor={colorByVertical}
                    tagType='outline'
                    TagLeftIcon={getIconByString('alertas')}
                    tagDisableHover
                    subjectTagLabel={tagLabel}
                    subjectTagOnClick={function noRefCheck() {
                        return {}
                    }}
                    subjectTagColor={colorByVertical}
                    subjectTagDisableHover
                    authorTagLabel={getDate(
                        content._source.source.published_at
                    )}
                    authorTagDisableHover
                    relatedTagLabel={formatHour(content)}
                    relatedTagDisableHover
                    hasSeparator={isMobile}
                    titleSearchTerm={''}
                />
            )
        } else
            return (
                <CardDS
                    hasSeparator={isMobile}
                    title={content._source.title.headline}
                    tagColor='jotaOrange'
                    titleSearchTerm={''}
                    newsLink={`${checkSpecialOrInfoNews(
                        content._source.categories,
                        content._source.source.slug,
                        null,
                        content._source.source.uri
                    )}`}
                    size='small'
                    image={content?._source?.featured_image?.img_uri ?? ''}
                    tagLabel={content._source?.categories[0]?.name}
                    tagDisableHover
                    relatedTagLabel={formatHour(content)}
                    multipleAuthors={content?._source?.authors}
                    multipleAuthorsClickEvent={(authorUrl) => {
                        if (authorUrl) {
                            return router.push(`/autor/${authorUrl}`)
                        }
                    }}
                    authorTagLabel={' '}
                    subjectTagLabel={
                        content._source.source.recycled
                            ? CONTEUDO_LIBERADO
                            : undefined
                    }
                    subjectTagDisableHover
                    isSponsored={content._source.source.recycled ?? false}
                />
            )
    }
    const showInfoCard = (content: any) => {
        return (
            <CardDS
                key={generateUUID()}
                title={
                    content.alternative?.title
                        ? content.alternative.title
                        : content.title
                }
                titleSearchTerm={''}
                newsLink={`${checkSpecialOrInfoNews(
                    content.categories,
                    content.slug,
                    content.permalink || content.link
                )}`}
                size='small'
                image={
                    content.thumbnail ? content.thumbnail.url : content.imagem
                }
                tagLabel={
                    content.alternative?.hat
                        ? content.alternative?.hat
                        : content.hat
                }
                tagDisableHover
                multipleAuthors={content?.authors}
                multipleAuthorsClickEvent={(authorUrl) => {
                    if (authorUrl) {
                        return router.push(`/autor/${authorUrl}`)
                    }
                }}
                authorTagLabel={' '}
                subjectTagLabel={
                    content.inherits_from_PRO ? CONTEUDO_LIBERADO : undefined
                }
                subjectTagDisableHover
                isSponsored={
                    (content.type === PATROCINADO ||
                        content.inherits_from_PRO) ??
                    false
                }
                relatedTagLabel={
                    content.published_at
                        ? getDate(content.published_at)
                        : decodeHtmlEntities(content.categories[0].name)
                }
                relatedTagDisableHover
                hasSeparator={false}
            />
        )
    }

    return (
        <ListContainer isSearchPage={isSearchPage}>
            {content?.map((item) => {
                if (type === 'os' || type === 'pro-info') {
                    return showOSCard(item)
                } else {
                    return showInfoCard(item)
                }
            })}
        </ListContainer>
    )
}

export default ListComponent

export const ListContainer = styled.div<{
    isSearchPage?: boolean
}>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${spaces.jotaSpacing44};
    margin-bottom: ${spaces.jotaSpacing44};
    margin-top: 0;
    padding: ${(props) => (props.isSearchPage ? '0px' : '44px 44px 0 44px')};

    & > div:last-child {
        border-bottom: none;
    }

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        grid-template-columns: 1fr;
        gap: ${spaces.jotaSpacing24};
        padding-inline: ${(props) => (props.isSearchPage ? '0' : '36px')};
        margin-bottom: ${spaces.jotaSpacing44};
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        padding: 44px 16px;
    }
`
