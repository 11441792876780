import { BREAKPOINTS } from '@App/libs/utils/constants/layout'
import { tokens } from '@JOTAJornalismo/jota-design-system'
import styled from 'styled-components'
const { spaces, fonts, colors, borders } = tokens

export const PageContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        flex-direction: column;
    }
`
export const SearchContent = styled.div<{ value?: string }>`
    display: flex;
    flex-direction: column;
    margin: ${spaces.jotaSpacing44};
    align-items: center;
    width: 100%;
    align-items: flex-start;

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        width: inherit;
        margin: ${spaces.jotaSpacing36} ${spaces.jotaSpacing16}
            ${spaces.jotaSpacing44} ${spaces.jotaSpacing16};
    }

    @media only screen and (min-width: ${BREAKPOINTS.SM_PX}) and (max-width: ${BREAKPOINTS.MD_PX}) {
        margin: ${spaces.jotaSpacing36};
    }
`
export const SearchHeader = styled.div<{ isEmpty?: boolean }>`
    width: 100%;
    margin-bottom: ${spaces.jotaSpacing44};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props) =>
        props.isEmpty
            ? `none}`
            : `1px solid ${colors.transparent.jotaTGrayLight40}`};

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        flex-direction: column;
        align-items: flex-start;
        border-bottom: none;
    }
`
export const SearchTitle = styled.h1<{ isEmpty?: boolean }>`
    ${fonts.jotaHeading04};
    color: ${colors.black.jotaBlack};
    margin: ${spaces.jotaSpacing12} 0 0 0;
    padding-bottom: ${(props) => props.isEmpty && spaces.jotaSpacing8};

    border-bottom: ${(props) =>
        props.isEmpty && `1px solid ${colors.transparent.jotaTGrayLight40}`};

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        margin-top: ${spaces.jotaSpacing12};
    }
`
export const SearchQuote = styled.p<{ skipMargin?: boolean }>`
    ${fonts.jotaBodyLarge};
    color: ${colors.gray.jotaGrayDark};
    margin: ${(props) =>
        props.skipMargin ? `0` : `0 0 ${spaces.jotaSpacing44} 0`};
    text-align: left;
    width: 100%;

    & > span {
        font-weight: 700;
    }

    & > strong {
        font-weight: 700;
        color: ${colors.black.jotaBlack};
    }

    & > p {
        margin: 0;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        & > span {
            font-weight: 700;
        }

        & > strong {
            color: ${colors.black.jotaBlack};
        }
    }
`

export const SearchAllResults = styled.h2`
    ${fonts.jotaHeading05};
    color: ${colors.black.jotaBlack};
    margin: 0 0 ${spaces.jotaSpacing24} 0;
    text-align: left;
    width: 100%;
`

export const SearchResultsGrid = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${spaces.jotaSpacing44};
    margin-bottom: ${spaces.jotaSpacing44};
    margin-top: 0;
    padding: 0;

    & > div:last-child {
        border-bottom: none;
    }

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        grid-template-columns: 1fr;
        gap: ${spaces.jotaSpacing24};
        padding: 0;
        margin-bottom: ${spaces.jotaSpacing44};
    }
`

export const SearchHeaderLeft = styled.div`
    width: inherit;
`
export const SearchHeaderRight = styled.div`
    margin-top: ${spaces.jotaSpacing24};
    min-width: fit-content;
    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        width: inherit;
    }
`

export const LastNewsContainer = styled.div`
    & > div {
        padding-inline: inherit;
    }
`

export const InputContainer = styled.div`
    margin-top: ${spaces.jotaSpacing24};
    background: ${colors.transparent.jotaTGrayLighter40};
    padding: ${spaces.jotaSpacing12};
    border-radius: ${borders.jotaRadiusDefault};
`

export const LastTitle = styled.h2`
    ${fonts.jotaHeading05};
    color: ${colors.black.jotaBlack};
    margin: ${spaces.jotaSpacing44} 0 0 0;
`

export const LastGrid = styled.ul`
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${spaces.jotaSpacing44};

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        grid-template-columns: 1fr;

        & > div:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
`

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 70px;
`
